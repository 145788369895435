import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'
import { Icon } from '../icon/icon'

export const StyledImage = styled.div`
    position: relative;
    display: inline-block;
    margin-bottom: ${props => props.theme.typography.pxToRem(36)};
    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: ${props => props.theme.typography.pxToRem(40)};
    }

    &:hover {
      transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
`

export const StyledOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: background-color 0.15s ease 0s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const StyledVideo = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.background.default};
  border-bottom-width: ${props => props.theme.typography.pxToRem(5)};
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(24)};
  }

  margin-bottom: ${props => props.theme.typography.pxToRem(40)};
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: left;
`

export const StyledPlayIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${props => props.theme.typography.pxToRem(17)};
  pointer-events: none;
  opacity: 0.9;
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(24)};
  }

  &:hover {
    box-shadow: 0 0 ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(-1)} ${props => props.theme.palette.text.secondary};
    transition: transform 0.2s ease-in, opacity 0.2s ease-in-out;
    opacity: 1;
  }
`
export const StyledCircleIcon = styled(Icon)`
  border-radius: ${props => props.theme.typography.pxToRem(47)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.9;

  &:hover {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
    box-shadow: 0 0 ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(-1)} ${props => props.theme.palette.text.secondary};
    cursor: pointer;
    opacity: 1;
  }
`


export type VideoContainerProps = {
    ncol: number
}

export const VideoContainer = styled.div<VideoContainerProps>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: auto;
  max-width: ${props => props.theme.typography.pxToRem(1349)};
  padding: ${props => props.theme.typography.pxToRem(24)};
  grid-gap: ${props => props.theme.typography.pxToRem(40)} 0;
  ${props => props.theme.breakpoints.up('sm')} {
    grid-gap: ${props => props.theme.typography.pxToRem(40)} ${props => (props.ncol == 3 ? props.theme.typography.pxToRem(64) : (props.ncol == 2 ? props.theme.typography.pxToRem(74): props.theme.typography.pxToRem(115)))};
    grid-template-columns: repeat(${props => props.ncol}, 1fr);
    padding: unset;
  }

  ${StyledVideo} {
    overflow: hidden;
    ${props => props.ncol === 1 && css`
      ${props.theme.breakpoints.up('sm')} {
        margin: 0 ${props => props.theme.typography.pxToRem(115)};
      }
    `}

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  ${StyledCircleIcon} {
    width: ${props => props.theme.typography.pxToRem(58)};
    height: ${props => props.theme.typography.pxToRem(58)};
    ${props => props.theme.breakpoints.up('sm')} {
      ${props => props.ncol && (props.ncol === 1 || props.ncol === 2) && css`
        width: ${props => props.theme.typography.pxToRem(94)};
        height: ${props => props.theme.typography.pxToRem(94)};
      `}
      ${props => props.ncol && props.ncol === 3 && css`
        width: ${props => props.theme.typography.pxToRem(80)};
        height: ${props => props.theme.typography.pxToRem(80)};
      `}
    }
  }

`
