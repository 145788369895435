import { attributesToProps, DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { button } from './button'
import { headings } from './headings'
import { iframe } from './iframe'
import { img } from './img'
import { table } from './table'
import { DomParser, DomParserProps, Utils } from './domParser'
import { isTag } from 'domhandler/lib/node'
import { forms } from './forms'

export const parsers: Record<string, DomParser> = {
  button,
  headings,
  iframe,
  img,
  table,
  ...forms,
}

export const runParsers = (domNode: DOMNode, options: HTMLReactParserOptions) => {
  if (isTag(domNode)) {
    const props: DomParserProps = {
      ...attributesToProps(domNode.attribs),
      children: domToReact(domNode.children, options),
    }

    const utils: Utils = {
      classes: () => typeof props.className === 'string' ? props.className.split(/\s+/).map(s => s.trim()).filter(Boolean) : [],
      render: (nodes: DOMNode[]) => domToReact(nodes, options),
    }

    for (const [key, parser] of Object.entries(parsers)) {
      switch (typeof parser.match) {
      case 'function':
        if (parser.match(domNode, props, utils)) { // eslint-disable-line unicorn/prefer-regexp-test
          return parser(domNode, props, utils)
        }

        break
      case 'string':
        if (parser.match === domNode.name) {
          return parser(domNode, props, utils)
        }

        break
      case 'object': // Array
        if (parser.match.includes(domNode.name)) {
          return parser(domNode, props, utils)
        }

        break
      case 'undefined':
        if (key === domNode.name) {
          return parser(domNode, props, utils)
        }

        break
      }
    }
  }

  return domNode
}