import React, { ComponentProps } from 'react'
import styled from 'styled-components'

// No empty source lint fix can be removed
export const StyledFlexibleContentWrapper = styled.div`
  opacity: 1;
`

export type FlexibleContentWrapperProps = ComponentProps<typeof StyledFlexibleContentWrapper> & {
  //
}

export const FlexibleContentWrapper: React.FC<FlexibleContentWrapperProps> = ({ children, ...props }) => {
  return (
    <StyledFlexibleContentWrapper {...props}>
      {children}
    </StyledFlexibleContentWrapper>
  )
}