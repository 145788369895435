import React from 'react'
import styled from 'styled-components'
import { Videos } from '../../components/video/Videos'
import { DomParser } from './domParser'

const StyledVideo = styled(Videos)`
  height: ${props => props.theme.typography.pxToRem(400)};
  width: ${props => props.theme.typography.pxToRem(400)};
`

export const iframe: DomParser = (element, props) => (
  <StyledVideo videos={[{ videoSource: props.src as string }]} ncol={0} />
)